.App {
  text-align: center;
}
.routes {
    margin: 20px;
    padding: 20px;
    background-color: #f9f9f9;
}

.header {
  background-color: #f0f0f0;
  box-shadow: 0px 3px 5px rgba(0, 0, 0, 0.1); /* Optional: adds a shadow */
}

nav {
  background-color: #f0f0f0;
}

nav ul {
  list-style-type: none;
  padding: 0;
  margin: 0;
  display: flex;
}

nav li {
  flex: 1;
  text-align: center;
  padding: 10px;
}

nav a {
  text-decoration: none;
  color: #333;
  display: block;
  padding: 8px;
  border: 1px solid #ccc;
  border-radius: 5px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  box-sizing: border-box;
}

nav a:hover {
  background-color: #e0e0e0;
}


/* To make the modal wider */
.wider-modal-dialog {
  max-width: 80%;
}

/* To make the content within the modal scrollable */
.wider-modal-content {
  overflow-x: auto;
}

/* Applies to tables within your wider-modal-content */
.wider-modal-content .table.table-striped.table-hover {
  min-width: 100%;
}

/* Fixes table layout and applies word wrapping */
.wider-modal-content .table {
  table-layout: fixed;
  word-wrap: break-word;
}

/* Reducing padding and font size in table cells */
.wider-modal-content .table td, .wider-modal-content .table th {
  padding: 0.5rem;
  font-size: 14px;
}


